import { all, put, takeLatest } from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'

import { getClient } from 'apolloClient'
import {
  getClusterContentsError,
  getClusterContentsSuccess,
  getClusterInfrastructureError,
  getClusterInfrastructureSuccess,
} from './actions'
import { GET_CLUSTER_CONTENTS, GET_CLUSTER_INFRASTRUCTURE } from './constants'

const GET_CLUST_CONTENT = gql`
  query getClusterContent($srn: String!) {
    Accounts(where: { srn: { value: $srn } }) {
      items {
        srn
        ...containedNamespaces
      }
    }
  }

  fragment containedNamespaces on Resource {
    contains {
      items {
        srn
        account
        createdDate
        observedDate
        active
        name
        friendlyName
        tagSet
        ... on Resourcegroup {
          type
        }
        ...containedWorkloads
      }
    }
  }

  fragment containedWorkloads on Resource {
    contains {
      items {
        srn
        name
        friendlyName
        label
        ... on Workload {
          type
        }
        ... on User {
          type
        }
        ... on Networkcomponent {
          type
        }
        ... on Identityreference {
          type
        }
        ... on Secretstore {
          type
        }
      }
    }
  }
`

function* getClusterContents(action) {
  try {
    const { srn } = action.payload
    const client = yield getClient()
    const result = yield client.query({
      query: GET_CLUST_CONTENT,
      variables: { srn },
    })
    const path = ['data', 'Accounts', 'items', 0, 'contains', 'items']
    const data = _.get(result, path, null)
    if (data === null) {
      throw 'result did not contain data at: ' + path.join('/')
    }
    yield put(getClusterContentsSuccess({ data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('There was an error fetching cluster contents: ', e)
    yield put(getClusterContentsError())
  }
}

const GET_CLUSTER_INFRA_QUERY = gql`
  query getK8sClusterContentsForAccount($srn: String) {
    Accounts(
      where: {
        srn: { value: $srn }
        type: { value: K8SCluster }
        name: { value: "rascalsakscluster" }
      }
    ) {
      items {
        name
        isIn(
          where: {
            label: { value: "Compute" }
            on__Compute: { type: { value: AzureKubernetesCluster } }
          }
        ) {
          items {
            contains {
              items {
                srn
                label
                active
                createdDate
                ... on Resource {
                  name
                }
                ... on Network {
                  type
                }
                ... on Networksubnet {
                  type
                }
                ... on Networkpolicy {
                  type
                }
                ... on Blockstorage {
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`

function* getClusterInfrastructure(action) {
  try {
    const { srn } = action.payload
    const client = yield getClient()
    const result = yield client.query({
      variables: { srn },
      query: GET_CLUSTER_INFRA_QUERY,
    })

    const path = [
      'data',
      'Accounts',
      'items',
      0,
      'isIn',
      'items',
      0,
      'contains',
      'items',
    ]
    const data = _.get(result, path, null)
    if (data === null) {
      throw 'no data at path ' + path.join('/')
    }
    yield put(getClusterInfrastructureSuccess({ data }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('There was an error fetching cluster infrastructure: ', e)
    yield put(getClusterInfrastructureError())
  }
}

function* k8sClusterNodeViewSaga() {
  yield all([
    takeLatest(GET_CLUSTER_CONTENTS, getClusterContents),
    takeLatest(GET_CLUSTER_INFRASTRUCTURE, getClusterInfrastructure),
  ])
}

export default k8sClusterNodeViewSaga
