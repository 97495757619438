import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import BorderedCard from 'components/BorderedCard'
import DataTable from 'components/DataTable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SectionHeader from 'components/SectionHeader'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'

import messages from './messages'

/**
 * This component displays a table of Infrastructure (nodes, disks, etc.) in a
 * kubernetes cluster.
 */
export class K8sInfrastructure extends Component {
  getTableData = () => {
    return this.props.clusterInfrastructure
      .get('data')
      .map(row => {
        return {
          ..._.pick(row.toJS(), [
            'name',
            'label',
            'type',
            'active',
            'createdDate',
            'srn',
          ]),
        }
      })
      .toJS()
  }

  renderBody = () => {
    if (this.props.clusterInfrastructure.get('loading')) {
      return <SquareLoadingAnimation />
    }

    if (this.props.clusterInfrastructure.get('error')) {
      return <DynamicFormattedMessage {...messages.clusterInfraLoadError} />
    }

    if (this.props.clusterInfrastructure.get('data')) {
      const tableData = this.getTableData()
      return <DataTable data={tableData} />
    }

    // eslint-disable-next-line no-console
    console.warn('K8sInfrastructure: received invalid clusterInfrastructure')
    return null
  }

  render() {
    return (
      <BorderedCard style={{ height: '100%' }}>
        <SectionHeader>
          <DynamicFormattedMessage {...messages.infrastructureSectionHeader} />
        </SectionHeader>
        <div style={{ height: 'calc(100% - 56px)' }}>{this.renderBody()}</div>
      </BorderedCard>
    )
  }
}

K8sInfrastructure.propTypes = {
  clusterInfrastructure: ImmutablePropTypes.contains({
    data: ImmutablePropTypes.list,
    error: PropTypes.bool,
    loading: PropTypes.bool,
  }).isRequired,
}

export default K8sInfrastructure
