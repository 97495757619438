import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import {
  GET_CLUSTER_CONTENTS,
  GET_CLUSTER_CONTENTS_SUCCESS,
  GET_CLUSTER_CONTENTS_ERROR,
  GET_CLUSTER_INFRASTRUCTURE,
  GET_CLUSTER_INFRASTRUCTURE_SUCCESS,
  GET_CLUSTER_INFRASTRUCTURE_ERROR,
} from './constants'

export const initialState = fromJS({
  clusterContents: {
    data: null,
    error: false,
    loading: false,
  },
  clusterInfrastructure: {
    data: null,
    error: false,
    loading: false,
  },
})

const k8sClusterNodeViewReducer = handleActions(
  {
    [GET_CLUSTER_CONTENTS]: state =>
      state
        .setIn(['clusterContents', 'loading'], true)
        .setIn(['clusterContents', 'error'], false),
    [GET_CLUSTER_CONTENTS_ERROR]: state =>
      state
        .setIn(['clusterContents', 'loading'], false)
        .setIn(['clusterContents', 'error'], true),

    [GET_CLUSTER_CONTENTS_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['clusterContents', 'loading'], false)
        .setIn(['clusterContents', 'error'], false)
        .setIn(['clusterContents', 'data'], fromJS(payload.data)),
    [GET_CLUSTER_INFRASTRUCTURE]: state =>
      state
        .setIn(['clusterInfrastructure', 'loading'], true)
        .setIn(['clusterInfrastructure', 'error'], false),
    [GET_CLUSTER_INFRASTRUCTURE_ERROR]: state =>
      state
        .setIn(['clusterInfrastructure', 'loading'], false)
        .setIn(['clusterInfrastructure', 'error'], true),

    [GET_CLUSTER_INFRASTRUCTURE_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['clusterInfrastructure', 'loading'], false)
        .setIn(['clusterInfrastructure', 'error'], false)
        .setIn(['clusterInfrastructure', 'data'], fromJS(payload.data)),
  },
  initialState
)

export default k8sClusterNodeViewReducer
