import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectK8sClusterNodeView = state =>
  state.get('k8sClusterNodeView') || Map()

export const selectClusterContents = createSelector(
  selectK8sClusterNodeView,
  state => {
    return state.get('clusterContents') || Map()
  }
)
export const selectClusterInfrastructure = createSelector(
  selectK8sClusterNodeView,
  state => {
    return state.get('clusterInfrastructure') || Map()
  }
)
