import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'

import BorderedCard from 'components/BorderedCard'
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import DataTable from 'components/DataTable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SectionHeader from 'components/SectionHeader'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import TextLink from 'components/TextLink'

import { getTypeFromSrn } from 'utils/graphDataUtils'
import { getNodeViewPushParams } from 'utils/sonraiUtils'

import messages from './messages'

export class K8sClusterContents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gridAPI: null,
      viewingNamespace: null,
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.viewingNamespace != prevState.viewingNamespace) {
      if (this.gridAPI != null) {
        this.gridAPI.redrawRows()
      }
    }
  }

  clearViewingNamespace = () => {
    this.setState({ viewingNamespace: null })
  }

  handleClickViewWorkloads = namespace => {
    this.setState({ viewingNamespace: namespace.srn })
  }

  onGridLoad = ({ api }) => {
    this.setState({ gridAPI: api })
  }

  getCountOfContainedStuff = row => {
    const containsCounts = row
      .toJS()
      .contains.items.map(item => item.label)
      .filter(label => ['Workload', 'NetworkComponent', 'User'].includes(label))
      .reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: acc[cur] + 1,
        }),
        {
          Workload: 0,
          NetworkComponent: 0,
          User: 0,
        }
      )
    return containsCounts
  }

  getNamespaceData = () => {
    return this.props.clusterContents
      .get('data')
      .filter(row => {
        return 'K8SNamespace' === row.get('type')
      })
      .map(row => {
        return {
          ..._.pick(row.toJS(), [
            'name',
            'active',
            'createdDate',
            'observedDate',
          ]),
          ...this.getCountOfContainedStuff(row),
          srn: row.get('srn'),
          type: row.get('type'),
          viewWorkloads: true,
        }
      })
      .toJS()
  }

  getWorkloadData = () => {
    const namespace = this.props.clusterContents
      .get('data')
      .find(namespace => this.state.viewingNamespace === namespace.get('srn'))

    if (!namespace) {
      // eslint-disable-next-line no-console
      console.warn(
        'K8sClusterContents has entered an invalid state - no namespace with SRN: ' +
          this.state.viewingNamespace
      )
    }

    const workloads = namespace.getIn(['contains', 'items'])
    if (!workloads) {
      // eslint-disable-next-line no-console
      console.warn(
        'K8sClusterContent received namespace w/out workloads ' +
          this.state.viewingNamespace
      )
      return []
    }

    return workloads
      .map(row => {
        return {
          ..._.pick(row.toJS(), ['name', 'label', 'type', 'srn']),
          gotoNodeView: row.get('srn'),
        }
      })
      .toJS()
  }

  renderBody() {
    if (this.props.clusterContents.get('loading')) {
      return <SquareLoadingAnimation />
    }

    if (this.props.clusterContents.get('error')) {
      return (
        <DynamicFormattedMessage {...messages.errorLoadingClusterContents} />
      )
    }

    let tableData =
      this.state.viewingNamespace != null
        ? this.getWorkloadData()
        : this.getNamespaceData()
    tableData = _.sortBy(tableData, row => row.name)
    if (this.state.viewingNamespace) {
      tableData = _.sortBy(tableData, row => row.label)
    }

    return (
      <DataTable
        data={tableData}
        onLoad={this.onGridLoad}
        customColumnConfig={{
          name: {
            width: 350,
            minWidth: 350,
          },
          srn: {
            width: 500,
            minWidth: 500,
          },
          Workload: {
            headerName: 'Workloads',
            width: 150,
            maxWidth: 150,
          },
          NetworkComponent: {
            headerName: 'Network Components',
            width: 150,
            maxWidth: 150,
          },
          User: {
            headerName: 'Service Accounts',
            width: 150,
            maxWidth: 150,
          },
          gotoNodeView: {
            width: 150,
            minWidth: 150,
            pinned: 'right',
            headerName: '',
            suppressMenu: true,
            cellRendererFramework: params => {
              const srn = params.value
              const nodeType = getTypeFromSrn(srn)
              const urlParams = getNodeViewPushParams(srn, nodeType)

              return (
                <TextLink
                  color="primary"
                  onClick={() => {
                    this.props.push(urlParams)
                  }}
                >
                  Go To Details
                </TextLink>
              )
            },
          },
          viewWorkloads: {
            width: 150,
            minWidth: 150,
            pinned: 'right',
            headerName: '',
            suppressMenu: true,
            cellRendererFramework: params => {
              return (
                <TextLink
                  color="primary"
                  onClick={() => {
                    this.handleClickViewWorkloads(params.data)
                  }}
                >
                  View Contents
                </TextLink>
              )
            },
          },
        }}
      />
    )
  }

  renderBreadCrumb = () => {
    const breadCrumbItems = []

    if (this.state.viewingNamespace) {
      breadCrumbItems.push(
        <BreadcrumbItem key={'ns'}>
          <TextLink color="primary" onClick={this.clearViewingNamespace}>
            Namespaces
          </TextLink>
        </BreadcrumbItem>
      )
      const namespace = this.props.clusterContents
        .get('data')
        .find(namespace => this.state.viewingNamespace === namespace.get('srn'))

      if (!namespace) {
        // eslint-disable-next-line no-console
        console.warn(
          'K8sClusterContents has entered an invalid state - no namespace with SRN: ' +
            this.state.viewingNamespace
        )
      } else {
        breadCrumbItems.push(
          <BreadcrumbItem key={'ns-name'}>
            {namespace.get('name')}
          </BreadcrumbItem>
        )
      }
    } else {
      breadCrumbItems.push(
        <BreadcrumbItem key={'ns'}>Namespaces</BreadcrumbItem>
      )
    }

    return <Breadcrumb>{breadCrumbItems}</Breadcrumb>
  }

  render() {
    return (
      <BorderedCard style={{ height: '100%' }}>
        <SectionHeader>Contents</SectionHeader>
        {this.renderBreadCrumb()}
        <div style={{ height: 'calc(100% - 56px)' }}>{this.renderBody()}</div>
      </BorderedCard>
    )
  }
}

K8sClusterContents.propTypes = {
  clusterContents: ImmutablePropTypes.contains({
    data: ImmutablePropTypes.list,
    error: PropTypes.error,
    loading: PropTypes.bool,
  }),
  push: PropTypes.func.isRequired,
}

export default K8sClusterContents
