import { defineMessages } from 'react-intl'

export default defineMessages({
  clusterInfraLoadError: {
    id: 'app.components.NodeView.k8s.clusterInfraLoadError',
    defaultMessage:
      'There was an error loading the Cluster Infrastructure data',
  },
  infrastructureSectionHeader: {
    id: 'app.components.NodeView.k8s.infrastructureSectionHeader',
    defaultMessage: 'Cluster Infrastructure',
  },
})
