import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { DAEMON } from 'utils/constants'
import reducer from './reducers'
import sagas from './sagas'

import { push } from 'connected-react-router'
import { getClusterContents, getClusterInfrastructure } from './actions'
import { selectClusterContents, selectClusterInfrastructure } from './selectors'

import NodeViewHeader from 'containers/NodeViewHeader'
import NodeView from 'containers/NodeSolutionCenter/NodeView'
import K8sInfrastructure from 'components/NodeView/k8s/K8sInfrastructure'
import K8sClusterContents from './K8sClusterContents'

export class K8sClusterNodeViewCardLayout extends Component {
  componentDidMount() {
    this.props.getClusterContents({ srn: this.props.nodeId })
    this.props.getClusterInfrastructure({ srn: this.props.nodeId })
  }

  getGridContent = () => {
    return [
      <div key="namespaces">
        <K8sClusterContents
          clusterContents={this.props.clusterContents}
          push={this.props.push}
        />
      </div>,
    ]
  }

  getLayouts = () => {
    const layouts = {
      namespaces: {
        lg: { w: 2, h: 3.5 },
        xs: { w: 2, h: 3.5 },
      },
      infrastructure: {
        lg: { w: 2, h: 3.5 },
        xs: { w: 2, h: 3.5 },
      },
    }

    return layouts
  }

  getCols = () => {
    return {
      lg: 2,
      xs: 2,
    }
  }

  getTabs = () => {
    const InfrastructureContainer = styled.div`
      height: 650px;
    `
    return [
      <div label="Infrastructure" key="infrastructure">
        <InfrastructureContainer>
          <K8sInfrastructure
            clusterInfrastructure={this.props.clusterInfrastructure}
            push={this.props.push}
          />
        </InfrastructureContainer>
      </div>,
    ]
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />
        <div className="k8s-cluster-nodeviewcardlayout">
          <NodeView
            {...this.props}
            horizontal={this.props.horizontal}
            renderDetailsTab
            layouts={this.getLayouts()}
            cols={this.getCols()}
            gridContent={this.getGridContent()}
            tabs={this.getTabs()}
          />
        </div>
      </Fragment>
    )
  }
}

K8sClusterNodeViewCardLayout.propTypes = {
  horizontal: PropTypes.bool,
  nodeId: PropTypes.string,
  nodeData: PropTypes.object.isRequired,

  // ~~ bound action creators ~~
  push: PropTypes.func,
  getClusterContents: PropTypes.func,
  getClusterInfrastructure: PropTypes.func,

  // ~~~ props from redux ~~~
  clusterContents: ImmutablePropTypes.map,
  clusterInfrastructure: ImmutablePropTypes.map,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      getClusterContents,
      getClusterInfrastructure,
    },
    dispatch
  )
}

const mapStateToProps = createStructuredSelector({
  clusterContents: selectClusterContents,
  clusterInfrastructure: selectClusterInfrastructure,
})

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({ key: 'k8sClusterNodeView', reducer })
const withSaga = injectSaga({
  key: 'k8sClusterNodeView',
  saga: sagas,
  DAEMON,
})

export default compose(
  withConnect,
  withReducer,
  withSaga
)(K8sClusterNodeViewCardLayout)
