import { createAction } from 'redux-actions'
import {
  GET_CLUSTER_CONTENTS,
  GET_CLUSTER_CONTENTS_SUCCESS,
  GET_CLUSTER_CONTENTS_ERROR,
  GET_CLUSTER_INFRASTRUCTURE,
  GET_CLUSTER_INFRASTRUCTURE_SUCCESS,
  GET_CLUSTER_INFRASTRUCTURE_ERROR,
} from './constants'

export const getClusterContents = createAction(GET_CLUSTER_CONTENTS)
export const getClusterContentsSuccess = createAction(
  GET_CLUSTER_CONTENTS_SUCCESS
)
export const getClusterContentsError = createAction(GET_CLUSTER_CONTENTS_ERROR)

export const getClusterInfrastructure = createAction(GET_CLUSTER_INFRASTRUCTURE)
export const getClusterInfrastructureSuccess = createAction(
  GET_CLUSTER_INFRASTRUCTURE_SUCCESS
)
export const getClusterInfrastructureError = createAction(
  GET_CLUSTER_INFRASTRUCTURE_ERROR
)
